<template>
  <div class="login-form">
    <div class="welcome-text">
      Forgotten your password? Enter your Email address and we'll send you a link to reset it.
    </div>
    <form name="form" @submit.prevent="handleLogin">
      <div class="underline-input">
        <!--          <label for="email">Email</label>-->
        <input
            v-model="email"
            v-validate="'required'"
            type="text"
            placeholder="Email Address"
            class="form-control"
            name="email"
        />
      </div>
      <div
          v-if="errors.has('email')"
          class="alert alert-danger"
          role="alert"
      >
        Email is required!
      </div>
      <div class="flex-row-centered">
        <button class="rebutton" :disabled="loading">
            <span
                v-show="loading"
                class="spinner-border spinner-border-sm"
            ></span>
          <span>Reset Password</span>
        </button>
      </div>
      <div class="form-group">
        <div v-if="message" class="alert alert-danger" role="alert">
          {{ message }}
        </div>
      </div>
    </form>
    <div class="footer">
      <router-link to="/terms">Terms of Use</router-link>
      |
      <router-link to="/privacy">Privacy Policy</router-link>
    </div>
  </div>
</template>

<script>
import UserService from "@/services/user.service";

export default {
  name: 'Login',
  data() {
    return {
      email: '',
      loading: false,
      message: '',
    };
  },
  methods: {
    handleLogin() {
      this.loading = true;
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }

        if (this.email) {
          UserService.sendPasswordReset(this.email)
          this.$router.push('reset-password-landing')
        } else {
          this.loading = false
        }
      });
    },
  },
};
</script>

<style scoped>

.login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 10%
}

.welcome-text {
  text-align: center;
  font-weight: bold;
  color: #026cc0;
  margin-bottom: 10%;
}

label {
  display: block;
  margin-top: 10px;
}

.footer {
  display: block;
  position: fixed;
  left: 75%;
  transform: translate(-50%, 0);
  bottom: 7.5%;
  text-align: center;
  font-size: 0.75em;
}

</style>
